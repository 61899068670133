import AccordionTab from "../utils/AccordianTab";
import TextField from "@mui/material/TextField";
import { Autocomplete, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import React, { useEffect, useState, useRef } from "react";
import { baseUrl } from "../config/Config";
import { postApiCall } from "../../services/ApiHelper";
import { getuserdetailsdata } from "../../redux/slices/userContextDetailSlice";
import { updateAuthorizationData } from "../../redux/slices/authorizationDataSlice";
import moment from "moment";
import { updateErrorFields } from "../../redux/slices/submitFormErrorSlice";
import { ApproversErrorModel, approversPayload } from "../../interfaces/Interfaces";
import { useParams } from "react-router-dom";
import { toLower } from "lodash";
import proposalSlice from "../../redux/slices/proposalSlice";

const Authorization = (props: any) => {
  const dispatch = useAppDispatch();

  const formData = useAppSelector((state) => { return state.getFormData.formData; });
  const submitErrorModel = useAppSelector((state) => state.submitFormError);
  const userdata = useAppSelector(getuserdetailsdata);
  const commonSlice = useAppSelector((state) => { return state.commonData.commonData });
  const authorizationData = useAppSelector((state) => { return state.authorizationData.data })
  const approvedAmount = useAppSelector((state) => { return state.budgetedData.data.budgetedAmount });
  const budgetedData = useAppSelector((state) => state.budgetedData);
  const nonBudgetedData = useAppSelector((state) => state.nonBudgetedData);
  const ModProjectDetailData = useAppSelector((state) => state.modProjectDetailsData.data);
  const ecoTermChange = useAppSelector((state) => { return state.commonData.data });
  const leaseProjectDetails = useAppSelector((state) => state.leaseProjectDetail.data);
  const proposaldata = useAppSelector((state) => { return state.proposaldata })

  const [showValuationText, setshowValuationText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approversList, setApproversList] = useState<any>()
  const [valuationSelect, setValuationSelect] = useState(() => {
    if (formData?.isAmtHigherThanValuationPercentage === true) return "Yes";
    if (formData?.isAmtHigherThanValuationPercentage === false) return "No";
    if (formData?.isAmtHigherThanValuationPercentage === undefined) return "";
  });
  const [authApproversRequest, setAuthApproversRequest] = useState<approversPayload>({
    authType: "",
    fundName: "",
    amount: 0.0,
    approvedAmount: 0,
    isAmtHigherThanValuationPercentage: false,
    term: 0,
    sqft: 0.0,
    ecoTerms: null,
    terminationFee: "",
    terminationTime: "",
    isOmni: (formData?.propertyManagementCompanyName === "OMNI" ? true : false),
    isOmniBudgeted: (formData?.propertyManagementCompanyName === "OMNI" && formData?.formType) ? true : false,
    marketRate: null
  });

  const [showApprovers, setshowApprovers] = useState(true);

  const [selectedAssetManager, setSelectedAssetManager] = useState<{ name: string, email: string }>({ name: "", email: "" });
  const [selectedPropertyManager, setSelectedPropertyManager] = useState<{ name: string, email: string } | null>({ name: "", email: "" });

  useEffect(() => {
    dispatch(updateAuthorizationData({
      propertyManager: formData?.propManagerName,
      propertyManagerEmail: formData?.propManagerEmail,
      recommendedBy: formData?.assetManagerName,
      recommendedByEmail: formData?.assetManagerEmail,
      isAmtHigherThanValuationPercentage: formData?.isAmtHigherThanValuationPercentage,
      selectedApprovers: formData?.approvers,
      approversListModel: []
    }));

    if (formData?.fundType === "NAA" && toLower(formData?.authCode) === "ca" && formData?.isAmtHigherThanValuationPercentage !== null && formData?.isAmtHigherThanValuationPercentage !== undefined) {
      setshowValuationText(true);
      if (formData?.isFormEditable) {
        handleValuationChange(formData?.isAmtHigherThanValuationPercentage ? "Yes" : "No");
      }
    } else if (formData?.fundType === "NAA" && toLower(formData?.authCode) === "ca" && (formData?.authAmount !== null && formData?.authAmount !== undefined && formData?.authAmount > 0) && !userdata.isPropertyManager) {
      setshowValuationText(true);
    }

    setSelectedAssetManager(formData?.assetManager?.find((option: any) => option.name === authorizationData?.recommendedBy) || { name: formData?.assetManagerName ? formData?.assetManagerName : "", email: formData?.assetManagerEmail ? formData?.assetManagerEmail : "" })
    setSelectedPropertyManager(!formData?.disablePropertyManager ? (formData?.propertyManager?.find((option: any) => option.name === authorizationData?.propertyManager) || { name: formData?.propManagerName ? formData?.propManagerName : "", email: formData?.propManagerEmail ? formData?.propManagerEmail : "" }) : null);
  }, [formData]);

  const approverDetailsurl = `${baseUrl}Approvers/details`;

  useEffect(() => {
    if (commonSlice.formType === true && formData?.propertyManagementCompanyName !== "OMNI" && userdata?.isAssetManager && (formData?.isFormEditable || formData?.showApprovers)) {

      if (budgetedData.data.authAmount !== undefined && budgetedData.data.authAmount > 0) {
        authApproversRequest.amount = budgetedData.data.authAmount;
        authApproversRequest.approvedAmount = approvedAmount;

        if (formData?.fundType === "TIAA") {
          approverLevel();
          setshowApprovers(true);
        } else if (formData?.fundType === "NAA" && toLower(formData?.authCode) === "ca" ) {

          setshowValuationText(true);
          if (authorizationData.isAmtHigherThanValuationPercentage !== null && authorizationData.isAmtHigherThanValuationPercentage !== undefined && formData?.isFormEditable) {
            handleValuationChange(authorizationData.isAmtHigherThanValuationPercentage ? "Yes" : "No");

          }
        }

      } else if (nonBudgetedData.data.authorizationAmount !== undefined || budgetedData.data.authAmount !== undefined) {
        dispatch(updateAuthorizationData({
          ...authorizationData,
          selectedApprovers: []
        }));
        setshowApprovers(false);
        setshowValuationText(false);
      }
    } else {
      if (nonBudgetedData.data.authorizationAmount !== undefined && nonBudgetedData.data.authorizationAmount > 0 && userdata?.isAssetManager && (formData?.isFormEditable || formData?.showApprovers)) {

        authApproversRequest.amount = nonBudgetedData.data.authorizationAmount;
        authApproversRequest.approvedAmount = 0;
        authApproversRequest.isOmniBudgeted = (formData?.propertyManagementCompanyName === "OMNI" && commonSlice.formType);

        if (formData?.fundType === "TIAA") {
          approverLevel();
          setshowApprovers(true);
        } else if (formData?.fundType === "NAA" && toLower(formData?.authCode) === "ca" ) {
          setshowValuationText(true);
          if (authorizationData.isAmtHigherThanValuationPercentage !== null && authorizationData.isAmtHigherThanValuationPercentage !== undefined) {
            handleValuationChange(authorizationData.isAmtHigherThanValuationPercentage ? "Yes" : "No");
          }
        }
      } else if (nonBudgetedData.data.authorizationAmount !== undefined || budgetedData.data.authAmount !== undefined) {
        dispatch(updateAuthorizationData({
          ...authorizationData,
          selectedApprovers: []
        }));
        setshowApprovers(false);
        setshowValuationText(false);
      }
    }

  }, [commonSlice, approvedAmount]);

  function usePrevious(value: any) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  var prevMarketRate: any = usePrevious(authApproversRequest.marketRate);
  var prevAmount: any = usePrevious(authApproversRequest.amount);
  var prevLeaseTerms: any = usePrevious(authApproversRequest.term);
  var prevLeasePremises: any = usePrevious(authApproversRequest.sqft);

  useEffect(() => {
    if (userdata?.isAssetManager && (formData?.isFormEditable || formData?.showApprovers)) {
      const leaseTermsIsNotEmpty = (leaseProjectDetails.leaseTerms !== null && leaseProjectDetails.leaseTerms !== undefined
        && !Number.isNaN(leaseProjectDetails.leaseTerms) && leaseProjectDetails.leaseTerms > 0);
      const leasePremiseIsNotEmpty = (leaseProjectDetails.leasePremise !== null && leaseProjectDetails.leasePremise !== undefined
        && !Number.isNaN(leaseProjectDetails.leasePremise) && leaseProjectDetails.leasePremise > 0);
      const leaseMarketRateIsNotNull = leaseProjectDetails.leaseMarketRate !== null && leaseProjectDetails.leaseMarketRate !== undefined
        && leaseProjectDetails.leaseMarketRate !== "";
      const leaseAmountNotNull = (leaseProjectDetails.amount !== null && leaseProjectDetails.amount !== undefined
        && !Number.isNaN(leaseProjectDetails.amount) && leaseProjectDetails.amount > 0);

      authApproversRequest.fundName = formData?.fundName;
      authApproversRequest.authType = formData?.authCode;
      authApproversRequest.term = !leaseTermsIsNotEmpty ? 0 : leaseProjectDetails.leaseTerms;
      authApproversRequest.sqft = !leasePremiseIsNotEmpty ? 0 : leaseProjectDetails.leasePremise;
      authApproversRequest.marketRate = leaseProjectDetails.leaseMarketRate === "yes" ? true : (leaseProjectDetails.leaseMarketRate === "no" ? false : null);
      authApproversRequest.terminationFee = ""
      authApproversRequest.terminationTime = ""

      if (formData?.showTiaaOwnershipAmt) {
        authApproversRequest.amount = (leaseProjectDetails?.amount * (formData?.tiaaOwnershipShare / 100));
      } else {
        authApproversRequest.amount = leaseProjectDetails.amount;
      }

      if (leaseTermsIsNotEmpty || leasePremiseIsNotEmpty || leaseMarketRateIsNotNull || leaseAmountNotNull) {

        if (!(authApproversRequest.marketRate === prevMarketRate && authApproversRequest.amount === prevAmount &&
          authApproversRequest.term === prevLeaseTerms && authApproversRequest.sqft === prevLeasePremises)) {
          getLeaseApprovers();
          setshowApprovers(true);
        }

      } else {
        setshowApprovers(false);
      }
    }

  }, [commonSlice.leasePremises, commonSlice.leaseTerms, commonSlice.leaseamount, leaseProjectDetails.leaseMarketRate]);

  useEffect(() => {
    if (userdata?.isAssetManager && (formData?.isFormEditable || formData?.showApprovers)) {
      const terminationtimenotempty = proposaldata?.data?.TerminationTime !== undefined && proposaldata?.data?.TerminationTime !== null && proposaldata?.data?.TerminationTime !== ""
      const terminationfeenotempty = proposaldata?.data?.TerminationFee !== undefined && proposaldata?.data?.TerminationFee !== null && proposaldata?.data?.TerminationTime !== ""
      authApproversRequest.terminationFee = proposaldata?.data?.TerminationFee
      authApproversRequest.terminationTime = proposaldata?.data?.TerminationTime
      authApproversRequest.amount = 0
      authApproversRequest.marketRate = null
      if (terminationtimenotempty && terminationfeenotempty) {
        getLeaseApprovers();
        setshowApprovers(true);
      } else {
        setshowApprovers(false);
      }

    }
  }, [proposaldata?.data?.TerminationFee, proposaldata?.data?.TerminationTime]);

  useEffect(() => {
    if (formData?.authCode.toLocaleLowerCase() === "ca") {
      if (userdata?.isAssetManager && (formData?.isFormEditable || formData?.showApprovers) && formData?.authAmount > 0) {
        authApproversRequest.amount = parseFloat(formData?.authAmount);
        approverLevel()
      }
    }
  }, [userdata?.isAssetManager, formData?.authAmount, approvedAmount])

  useEffect(() => {

    if (userdata?.isAssetManager && (formData?.isFormEditable || formData?.showApprovers) && formData?.showTiaaOwnershipAmt &&
      (ModProjectDetailData.ecoTerms !== "undefined" && ModProjectDetailData.ecoTerms !== undefined && ModProjectDetailData.ecoTerms !== "")) {
      authApproversRequest.ecoTerms = (ModProjectDetailData.ecoTerms === "yes" ? true : ModProjectDetailData.ecoTerms === "no" ? false : null);
      setshowApprovers(true);
      approverLevel();
    }
    else if (userdata?.isAssetManager && (formData?.isFormEditable || formData?.showApprovers) && !formData?.showTiaaOwnershipAmt &&
      (ModProjectDetailData.authAmount !== "undefined" && ModProjectDetailData.authAmount !== undefined && ModProjectDetailData.authAmount !== "0" && ModProjectDetailData.authAmount !== 0 && ModProjectDetailData.authAmount !== "")) {
      authApproversRequest.amount = ModProjectDetailData.authAmount;
      setshowApprovers(true);
      approverLevel();
    } else {
      setshowApprovers(false);
    }

  }, [ModProjectDetailData.ecoTerms, ModProjectDetailData.authAmount])

  const approverLevel = async () => {
    authApproversRequest.authType = formData?.authCode;
    authApproversRequest.fundName = formData?.fundName;

    if (formData?.fundType === "TIAA" || formData?.authCode.toLocaleLowerCase() !== "ca") {
      const res: Array<any> = await postApiCall(approverDetailsurl, authApproversRequest).then((data: any) => data);
      setApproversList(res);
    }
    else if (formData?.fundType === "NAA" && formData?.authCode.toLocaleLowerCase() === "ca") {
      setshowValuationText(true)
    }
  }

  useEffect(() => {
    if (approversList !== undefined) {

      var tempApproversArray: Array<any> = [];
      approversList.forEach((element: any, index: number) => {
        tempApproversArray.push({ level: element.level, isEmpty: false, approver: index });
      });

      dispatch(updateAuthorizationData({
        ...authorizationData,
        approversListModel: tempApproversArray
      }));
    }

  }, [approversList]);

  const handleValuationChange = async (selectedValue: any) => {

    authApproversRequest.authType = formData?.authCode;
    authApproversRequest.fundName = formData?.fundName;
    authApproversRequest.isAmtHigherThanValuationPercentage = selectedValue === "Yes";
    setAuthApproversRequest(authApproversRequest);
    setValuationSelect(selectedValue as string);
    setLoading(true);
    await postApiCall(approverDetailsurl, authApproversRequest).then((data: any) => {
      setLoading(false);
      setApproversList(data);
      setshowApprovers(true);
    }).catch((err: any) => {
      const msg = err.response.data.description;
      console.log(msg);
      setLoading(false)
    });

  };

  const getLeaseApprovers = async () => {
    setshowApprovers(false);
    setAuthApproversRequest(authApproversRequest);
    setLoading(true);

    await postApiCall(approverDetailsurl, authApproversRequest).then((data: any) => {
      setLoading(false);
      setApproversList(data);
      setshowApprovers(true);
    }).catch((err: any) => {
      const msg = err.response.data.description;
      console.log(msg);
      setLoading(false)
    });
  };

  const onChangeSelecteApprovers = (index: number, newInputValue: any, value: any) => {
    var updatedApprovers = [];

    var filteredSelectedApprovers: Array<ApproversErrorModel> = submitErrorModel.data.approversListError?.filter((element: ApproversErrorModel) => element.approver !== index + 1);
    filteredSelectedApprovers.push({ level: value.level, isEmpty: false, approver: index + 1 });

    dispatch(updateErrorFields({ ...submitErrorModel.data, approversListError: filteredSelectedApprovers.sort((a, b) => a.approver - b.approver) }));

    if (newInputValue !== null) {
      const approver: any = authorizationData?.selectedApprovers?.find((elm: any) => elm.approver !== index + 1);
      console.log(approver , "approver")
      const newApprovers: any = {
        name: newInputValue?.name ? newInputValue.name : "",
        approver: index + 1,
        mail: newInputValue?.emailId ? newInputValue.emailId : "",
        level: value.level,
        comments: "",
        isApproved: false
      };
      updatedApprovers = approver ? [approver, newApprovers] : [newApprovers];
    } else {
      const approver = [...authorizationData?.selectedApprovers];
      updatedApprovers = approver.filter(e => e.approver !== index + 1);
    }

    dispatch(updateAuthorizationData({
      ...authorizationData,
      selectedApprovers: updatedApprovers
    }));
  }

  const tableCellStyle = {
    backgroundColor: "grey",
    color: "#fff",
  };
  const tableCellRows = {
    backgroundColor: "lightgrey",
    color: "#fff",
  };
  return (
    <AccordionTab
      title={"Authorization"}
      children={
        <div className="authorization-form-container">
          <label className="authorization-form-header">{formData?.verbiageText}</label>
          {formData?.approvalsInfo ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow style={tableCellStyle}>
                    <TableCell>Role</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Level</TableCell>
                    <TableCell>Date&Time</TableCell>
                    <TableCell>Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData?.approvalsInfo?.map((item: any, index: any) =>
                  (
                    <TableRow key={index} style={tableCellRows}>

                      <TableCell>{item.role}</TableCell>
                      <TableCell>{item.userName}</TableCell>
                      <TableCell>{item.level}</TableCell>
                      <TableCell>{moment(item.createdOn).format("MMM-DD-YYYY hh:mm A")}</TableCell>
                      <TableCell>{item.comments}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Grid container spacing={2}>
              {formData?.showPropertyManager &&
                <Grid item xs={12} md={6}>
                  <Grid>
                    <label htmlFor="field1">{formData?.showLeaseBroker ? "Leasing Broker / Property Manager" : "Property Manager"}</label>
                    {!formData?.disablePropertyManager && (!userdata?.isAdmin && <span className="mandatory"> * </span>)}
                  </Grid>
                  <Grid>
                    <Autocomplete
                      fullWidth
                      size="small"
                      disabled={!formData?.isFormEditable || formData?.disablePropertyManager}
                      disablePortal
                      data-testid="property-select property"
                      value={selectedPropertyManager}
                      options={formData?.propertyManager ?? []}
                      getOptionLabel={(option: any) => option?.name}
                      isOptionEqualToValue={(option: any, value: any) => {
                        return option?.name === value?.name;
                      }}
                      onChange={(event, newInputValue: any) => {
                        if (newInputValue === null) {
                          setSelectedPropertyManager({ name: "", email: "" });
                        } else {
                          setSelectedPropertyManager(newInputValue);
                        }
                        dispatch(updateErrorFields({
                          ...submitErrorModel.data,
                          propManager: false

                        }));
                        dispatch(updateAuthorizationData({
                          ...authorizationData,
                          propertyManager: newInputValue ? newInputValue?.name : "",
                          propertyManagerEmail: newInputValue ? newInputValue?.email : ""
                        }));
                      }}
                      renderInput={(params) => <TextField {...params} role="property manager" error={!formData?.disablePropertyManager && !userdata.isAdmin && submitErrorModel.data.propManager} />}
                    />
                  </Grid>
                </Grid>
              }
              <Grid item xs={12} md={6}>
                <Grid>
                  <label htmlFor="field2">Recommended By</label> <span className="mandatory"> * </span>
                </Grid>
                <Grid>
                  <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    disabled={!formData?.isFormEditable}
                    data-testid="property-select asset"
                    value={selectedAssetManager}
                    options={formData?.assetManager ?? []}
                    getOptionLabel={(option: any) => option?.name}
                    isOptionEqualToValue={(option: any, value: any) => {
                      return option?.name === value?.name;
                    }}
                    onChange={(event, newInputValue: any) => {

                      if (newInputValue === null) {
                        setSelectedAssetManager({ name: "", email: "" });
                      } else {
                        setSelectedAssetManager(newInputValue);
                      }

                      dispatch(updateErrorFields({
                        ...submitErrorModel.data,
                        assetManager: false
                      }));

                      dispatch(updateAuthorizationData({
                        ...authorizationData,
                        recommendedBy: newInputValue ? newInputValue?.name : "",
                        recommendedByEmail: newInputValue ? newInputValue?.email : ""
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} error={submitErrorModel.data.assetManager} />}
                  />
                </Grid>
              </Grid>
              {!formData?.showPropertyManager && <Grid item md={6} className="grid-hide">
              </Grid>}
              {showValuationText &&
                <Grid item xs={12} md={8}>
                  <Grid>
                    <label >Is requested auth greater then 20% of the current inv valuation?</label><span className="mandatory"> *</span>
                  </Grid>

                  <Grid >
                    <FormControl sx={{ m: 1, minWidth: 120, margin: 0, width: "15%" }} size="small">
                      <Select
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected === '') {
                            return <em>Select</em>;
                          }
                          return selected;
                        }}
                        data-testid="valuation-select"
                        value={valuationSelect}
                        inputProps={{ 'aria-label': 'Without label' }}
                        disabled={!formData?.isFormEditable && !formData?.showApprovers}
                        onChange={(event) => {
                          handleValuationChange(event.target.value);
                          dispatch(updateAuthorizationData({
                            ...authorizationData,
                            isAmtHigherThanValuationPercentage: event.target.value as string === "Yes",
                            selectedApprovers: []
                          }));
                        }}>
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>
                </Grid>
              }
              {!loading && (showApprovers || formData?.showApprovers) ? approversList?.map((value: any, index: any) => (
                <Grid item xs={12} md={6} key={index}>
                  <Grid>
                    <label >Approver {index + 1}</label> <span className="mandatory"> *</span>
                  </Grid>
                  <Grid>
                    <Autocomplete
                      size="small"
                      fullWidth
                      disablePortal
                      data-testid="property-select approval"
                      disabled={!formData?.isFormEditable && !formData?.showApprovers}
                      value={authorizationData?.selectedApprovers?.find((elm: any) => elm.approver === index + 1) || null}
                      options={value?.approvers ?? []}
                      getOptionLabel={(option: any) => option?.name}
                      isOptionEqualToValue={(option: any, value: any) => {
                        return option?.name === value?.name;
                      }}
                      onChange={(event, newInputValue) => {
                        onChangeSelecteApprovers(index, newInputValue, value);
                      }}
                      renderInput={(params) => <TextField  {...params} error={submitErrorModel.data.approversListError[index]?.isEmpty}
                      />}
                    />
                  </Grid>
                  <Grid className="grid-padding-level">
                    <TextField disabled={true} fullWidth size="small" value={value.level}></TextField>
                  </Grid>
                </Grid>
              )) : null}

              {!loading ? (approversList === undefined || approversList?.length == 0) && !formData?.isFormEditable && !formData?.showApprovers && formData?.approvers.map((value: any, index: any) => (
                <Grid item xs={12} md={6} key={index}>
                  <Grid>
                    <label >Approver {index + 1}</label>
                  </Grid>
                  <Grid>
                    <TextField disabled={true} fullWidth size="small" value={value.name}></TextField>
                  </Grid>
                  <Grid className="grid-padding-level">
                    <TextField disabled={true} fullWidth size="small" value={value.level}></TextField>
                  </Grid>
                </Grid>
              )) : null}
            </Grid >
          )
          }
        </div >
      }
    />
  );
};
export default Authorization;