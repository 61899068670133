import { Button, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../utils/Loader";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { toLower } from "lodash";
import proposalSlice, {
  resetProposalDataStateAction,
} from "../../redux/slices/proposalSlice";
import dayjs, { Dayjs } from "dayjs";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { baseUrl } from "../config/Config";
import { postApiCall } from "../../services/ApiHelper";
import { DialogBox } from "../utils/DialogBox";
import { ToastMessage } from "../utils/ToastMessage";
import { setLoading } from "../../redux/slices/loaderSlice";
import {
  getFormData,
  resetFormDataStateAction,
} from "../../redux/slices/getFormDataSlice";
import {
  updateErrorFields,
  resetSubmitFormErrorStateAction,
} from "../../redux/slices/submitFormErrorSlice";
import { resetBudgetedDataStateAction } from "../../redux/slices/budgetedDataSlice";
import { resetNonBudgetedDataStateAction } from "../../redux/slices/nonBudgetedDataSlice";
import { resetAuthorizationDataStateAction } from "../../redux/slices/authorizationDataSlice";
import { resetprojectPropertyDetailDataStateAction } from "../../redux/slices/propertyProjectDetailSlice";
import { RecommendbyValidator } from "../utils/Validators/RecommendbyValidators";
import {
  propertiesDetailsType,
  toast,
  saveSubmitFormData,
} from "../../interfaces/Interfaces";

import {
  validatePropertyProjectDetails,
  isPropertyProjectDetailsError,
} from "../utils/Validators/PropertyProjectDetailValidation";
import {
  approvalErrorHandler,
  isApprovalError,
} from "../utils/Validators/ApproversValidation";
import { ApproveRejectDialog } from "../utils/ApproveRejectDialog";
import { resetAuthDataStateAction } from "../../redux/slices/authDataSlice";
import { getuserdetailsdata } from "../../redux/slices/userContextDetailSlice";
import { resetleaseProjectDetailStateAction } from "../../redux/slices/leaseProjectDetailSlice";
import { resetModprojectDetailDataStateAction } from "../../redux/slices/modProjectDetailSlice";
import { resetJVReasonForAuthStateAction } from "../../redux/slices/jvAuthReasonSlice";

const CapAuthActionButtons = (props: any) => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const formData = useAppSelector(getFormData);
  const uploadedDoc = useAppSelector((state) => state.fileUploadData.data);
  const submitErrorModel = useAppSelector((state) => state.submitFormError);
  const budgetedData = useAppSelector((state) => state.budgetedData);
  const nonBudgetedData = useAppSelector((state) => state.nonBudgetedData);
  const propertyProjectDetails = useAppSelector(
    (state) => state.propertyProjectDetail
  );
  const authorizationData = useAppSelector((state) => state.authorizationData);
  const commonSlice = useAppSelector((state) => {
    return state.commonData.commonData;
  });
  const leaseProjectDetails = useAppSelector((state) => {
    return state.leaseProjectDetail;
  });
  const proposaldata = useAppSelector((state) => state.proposaldata.data);

  const ModProjectDetailsData = useAppSelector((state) => {
    return state.modProjectDetailsData.data;
  });
  const userdata = useAppSelector(getuserdetailsdata);
  const jvAuthreasonData = useAppSelector((state) => {
    return state.jvAuthReason.data;
  });

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openRestorePopup, setOpenRestorePopup] = useState(false);
  const [openRecallPopup, setOpenRecallPopup] = useState(false);
  const [approveRejectDialogBox, setApproveRejectDialogBox] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [approveRejectComment, setApproveRejectComment] = useState("");
  const [saveSubmitFormData, setSaveSubmitData] = useState<any>();
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });

  const today = dayjs();

  const propertyProjectDetailsCall = (projectDetails: any) => {
    var filteredPropertyDetailList: propertiesDetailsType[] = [];
    if (projectDetails.data.projectDetails !== undefined) {
      var propertyDetailObject = {
        name: "",
        description: "",
        projectManagementFeeNA: false,
        projectManagementFee: 0,
        dateOfCompletion: undefined,
        authorizationAmount:
          projectDetails?.data?.projectDetails.length === 1
            ? saveSubmitFormData?.AuthorizationAmount !== undefined
              ? saveSubmitFormData?.AuthorizationAmount
              : 0
            : 0,
      };

      var emptyArr: Array<propertiesDetailsType> = [
        {
          ...propertyDetailObject,
        },
      ];

      projectDetails?.data?.projectDetails.forEach((element: any) => {
        var tempObj = {
          name: element.name,
          description: element.description,
          projectManagementFeeNA: formData?.showProjectMgmtFee
            ? element.projectManagementFeeNA
            : null,
          projectManagementFee: formData?.showProjectMgmtFee
            ? element.projectManagementFee
            : null,
          dateOfCompletion: formData?.showProjectDate
            ? element.dateOfCompletion
            : null,
          authorizationAmount: formData?.showProjectAuthAmt
            ? projectDetails?.data?.projectDetails.length === 1 &&
              element.authorizationAmount === 0
              ? saveSubmitFormData?.AuthorizationAmount !== undefined
                ? saveSubmitFormData?.AuthorizationAmount
                : 0
              : element.authorizationAmount
            : null,
        };

        if (!element.isDeleted) {
          emptyArr.push(tempObj);
        }

        tempObj = { ...propertyDetailObject };
      });

      emptyArr = emptyArr.slice(1);

      filteredPropertyDetailList = emptyArr.filter(
        (element) =>
          element.authorizationAmount > 0 ||
          element.description?.length !== 0 ||
          element.dateOfCompletion !== undefined ||
          element.dateOfCompletion !== null ||
          element.name?.length !== 0 ||
          element.projectManagementFee > 0 ||
          element.projectManagementFeeNA === true
      );
    }
    return filteredPropertyDetailList;
  };

  const clearAuthStateAfterSuccess = () => {
    setToast(true);
    setToastMessage({
      message: "Authorization submitted sucessfully",
      severity: "success",
    });

    setTimeout(() => {
      dispatch(resetSubmitFormErrorStateAction());
      dispatch(resetBudgetedDataStateAction());
      dispatch(resetNonBudgetedDataStateAction());
      dispatch(resetAuthorizationDataStateAction());
      dispatch(resetprojectPropertyDetailDataStateAction());
      dispatch(resetAuthDataStateAction());
      dispatch(resetleaseProjectDetailStateAction());
      dispatch(resetProposalDataStateAction());
      dispatch(resetModprojectDetailDataStateAction());
      dispatch(resetFormDataStateAction());
      dispatch(resetJVReasonForAuthStateAction());

      setToast(false);
      nav("/");
    }, 4000);
  };

  const fieldErrorToastMessage = (msg: string) => {
    setToast(true);
    setToastMessage({
      message: msg,
      severity: "error",
    });
    setTimeout(() => setToast(false), 4000);
  };

  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
  };

  const submitAPICall = async () => {
    const submitUrl = `${baseUrl}Authorizations/submit`;
    await postApiCall(submitUrl, saveSubmitFormData)
      .then((response: any) => {
        if (String(response) == "Success") {
          dispatch(setLoading(false));

          clearAuthStateAfterSuccess();
        }
      })
      .catch((err: any) => {
        errorToastMsgwithCode(err);
      });
  };

  const approveRejectAPICall = async (
    approveRejectUrl: any,
    approveRequest: any
  ) => {
    await postApiCall(approveRejectUrl, approveRequest)
      .then((response: any) => {
        dispatch(setLoading(false));

        setToast(true);
        setToastMessage({
          message: isRejected
            ? "Authorization rejected sucessfully"
            : "Authorization approved sucessfully",
          severity: "success",
        });
        setTimeout(() => {
          setToast(false);
          nav("/");
        }, 4000);
      })
      .catch((err: any) => {
        dispatch(setLoading(false));
        errorToastMsgwithCode(err);
      });
  };

  useEffect(() => {
    if (
      formData?.parentComponent?.toLocaleLowerCase() === "captialauthorization"
    ) {
      var propertyProjectDetailsList = propertyProjectDetailsCall(
        propertyProjectDetails
      );
      var isOmni = formData?.propertyManagementCompanyName === "OMNI";

      setSaveSubmitData({
        TerminationFee: "",
        TerminationTime: "",
        LeasingCompany: "",
        PropertyMgmtCompany: "",
        ExpansionFee: "",
        ExtensionFee: "",
        ManagementFee: "",
        OtherFee: "",
        Id: formData?.id,
        AuthCode: formData?.authCode,
        FormTitle: formData?.formTitle,
        Fund: formData?.fundName,
        InvestmentId: formData?.invId,
        InvestmentName: formData?.invName,
        MultipleProperties: formData?.multipleProps === "No" ? false : true,
        Address: formData?.address !== null ? formData?.address : "",
        TotalInvestmentShare: formData?.totalInvestmentShare,
        InvestmentOccupancy: formData?.investmentOccupancy,
        OwnershipType: formData?.ownershipType,
        Type: formData?.type,
        TiaaOwnershipShare: parseFloat(formData?.tiaaOwnershipShare),
        Status: formData?.status,
        VerbiageText:
          formData?.verbiageText === null ? "" : formData?.verbiageText,
        IsOmni:
          formData?.propertyManagementCompanyName !== null && isOmni
            ? true
            : false,
        ReasonForNonBudgetedProject: !commonSlice.formType
          ? nonBudgetedData?.data?.rsnForNonBudgeted
            ? nonBudgetedData?.data?.rsnForNonBudgeted
            : ""
          : "",
        ProjectName: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.name
            : budgetedData?.data?.name
          : nonBudgetedData?.data?.name,
        ProjectType: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.projectType
            : budgetedData?.data?.projectType
          : nonBudgetedData?.data?.projectType,
        ProjectID: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.projectId
            : budgetedData?.data?.projectId
          : nonBudgetedData?.data?.projectId,
        ProjectSource: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.projectSource
            : budgetedData?.data?.projectSource
          : nonBudgetedData?.data?.projectSource,
        Description: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.projectDescription
            : budgetedData?.data?.projectDescription
          : nonBudgetedData?.data?.projectDescription,
        Bid: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.bid
            : budgetedData?.data?.bid
          : nonBudgetedData?.data?.bid,
        SelectedBidReason: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.nonCompetBidReason
            : budgetedData?.data?.nonCompetBidReason
          : nonBudgetedData?.data?.nonCompetBidReason,
        StartDate: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.startDate !== undefined
              ? nonBudgetedData?.data?.startDate
              : today
            : budgetedData?.data?.startDate !== undefined
            ? budgetedData?.data?.startDate
            : today
          : nonBudgetedData?.data?.startDate !== undefined
          ? nonBudgetedData?.data?.startDate
          : today,
        Duration: budgetedData?.data?.duration
          ? budgetedData?.data?.duration
          : nonBudgetedData?.data.duration,
        EndDate: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.endDate !== undefined
              ? nonBudgetedData?.data?.endDate
              : today
            : budgetedData?.data?.endDate !== undefined
            ? budgetedData?.data?.endDate
            : today
          : nonBudgetedData?.data?.endDate !== undefined
          ? nonBudgetedData?.data?.endDate
          : today,
        AuthorizationAmount: commonSlice.formType
          ? isOmni
            ? nonBudgetedData?.data?.authorizationAmount
            : budgetedData?.data?.authAmount
          : nonBudgetedData?.data?.authorizationAmount,
        OverBudgetReason: commonSlice.formType
          ? budgetedData.data.overBudgetReason == undefined
            ? ""
            : budgetedData.data.overBudgetReason
          : "",
        ApprovedProjectBudgetAmount:
          budgetedData.data.budgetedAmount == undefined
            ? 0
            : budgetedData.data.budgetedAmount,
        FormType: commonSlice?.formType,
        PropertiesDetails: propertyProjectDetailsList,
        PropManagerName: authorizationData?.data?.propertyManager,
        PropManagerEmail: authorizationData?.data?.propertyManagerEmail,
        AssetManagerName: authorizationData?.data?.recommendedBy,
        AssetManagerEmail: authorizationData?.data?.recommendedByEmail,
        IsAmtHigherThanValuationPercentage:
          authorizationData?.data?.isAmtHigherThanValuationPercentage,
        Approvers: authorizationData?.data?.selectedApprovers,
        LeaseTerm: 0,
        LeasePremises: 0,
        LeaseMarketRate: null,
        OfacDocCertified: null,
        documents: uploadedDoc,
      });
    }
  }, [
    budgetedData,
    nonBudgetedData,
    propertyProjectDetails,
    authorizationData,
    commonSlice.formType,
    uploadedDoc,
  ]);

  useEffect(() => {
    if (formData?.parentComponent?.toLocaleLowerCase() === "leaseauth") {
      setSaveSubmitData({
        Id: formData?.id,
        AuthCode: formData?.authCode,
        FormTitle: formData?.formTitle,
        Fund: formData?.fundName,
        InvestmentId: formData?.invId,
        InvestmentName: formData?.invName,
        MultipleProperties: formData?.multipleProps === "No" ? false : true,
        Address: formData?.address !== null ? formData?.address : "",
        TotalInvestmentShare: formData?.totalInvestmentShare,
        InvestmentOccupancy: formData?.investmentOccupancy,
        OwnershipType: formData?.ownershipType,
        Type: formData?.type,
        TiaaOwnershipShare: parseFloat(formData?.tiaaOwnershipShare),
        Status: formData?.status,
        VerbiageText:
          formData?.verbiageText === null ? "" : formData?.verbiageText,
        IsOmni: false,
        ProjectName: leaseProjectDetails.data.projectName,
        Description: leaseProjectDetails.data.detailedProjDesc,
        AuthorizationAmount: leaseProjectDetails.data.amount,
        ApprovedProjectBudgetAmount: 0,
        PropManagerName: authorizationData?.data?.propertyManager,
        PropManagerEmail: authorizationData?.data?.propertyManagerEmail,
        AssetManagerName: authorizationData?.data?.recommendedBy,
        AssetManagerEmail: authorizationData?.data?.recommendedByEmail,
        IsAmtHigherThanValuationPercentage: false,
        Approvers: authorizationData?.data?.selectedApprovers,
        LeaseTerm: leaseProjectDetails.data.leaseTerms,
        LeasePremises: leaseProjectDetails.data.leasePremise,
        LeaseMarketRate: leaseProjectDetails.data.leaseMarketRate,
        OfacDocCertified: leaseProjectDetails.data.ofacDoc,
        AuthAgreement: jvAuthreasonData.jvAgreement,
        AuthSection: jvAuthreasonData.jvSection,
        AuthDescription: jvAuthreasonData.jvDescription,
        documents: uploadedDoc,
      });
    }
  }, [jvAuthreasonData, leaseProjectDetails, authorizationData, uploadedDoc]);

  useEffect(() => {
    if (formData?.parentComponent?.toLocaleLowerCase() === "modifications") {
      setSaveSubmitData({
        Id: formData?.id,
        AuthCode: formData?.authCode,
        FormTitle: formData?.formTitle,
        Fund: formData?.fundName,
        InvestmentId: formData?.invId,
        InvestmentName: formData?.invName,
        MultipleProperties: formData?.multipleProps === "No" ? false : true,
        Address: formData?.address !== null ? formData?.address : "",
        TotalInvestmentShare: formData?.totalInvestmentShare,
        InvestmentOccupancy: formData?.investmentOccupancy,
        OwnershipType: formData?.ownershipType,
        Type: formData?.type,
        TiaaOwnershipShare: parseFloat(formData?.tiaaOwnershipShare),
        Status: formData?.status,
        VerbiageText: formData?.verbiageText,
        AssetManagerName: authorizationData?.data?.recommendedBy,
        AssetManagerEmail: authorizationData?.data?.recommendedByEmail,
        Approvers: authorizationData?.data?.selectedApprovers,
        ProjectName: ModProjectDetailsData?.name,
        Description: ModProjectDetailsData?.projectDescription,
        AuthorizationAmount:
          ModProjectDetailsData?.authAmount === ""
            ? "0"
            : ModProjectDetailsData?.authAmount,
        ecoTerms: ModProjectDetailsData?.ecoTerms,
        documents: uploadedDoc,
      });
    }
  }, [ModProjectDetailsData, authorizationData, uploadedDoc]);

  useEffect(() => {
    if (
      formData?.parentComponent?.toLocaleLowerCase() === "appointmentlcnpmc"
    ) {
      var propertyProjectDetailsList = propertyProjectDetailsCall(
        propertyProjectDetails
      );
      var isOmni = formData?.propertyManagementCompanyName === "OMNI";
      setSaveSubmitData({
        TerminationFee: proposaldata?.TerminationFee,
        TerminationTime: proposaldata?.TerminationTime,
        LeasingCompany: proposaldata?.Leasingcompany,
        PropertyMgmtCompany: proposaldata?.PropertyMgmtCompany,
        ExpansionFee: proposaldata?.ExpansionFee,
        ExtensionFee: proposaldata?.ExtensionFee,
        ManagementFee: proposaldata?.ManagementFee,
        Description: proposaldata?.projectDescription,
        ProjectName: proposaldata?.projectName,
        OtherFee: proposaldata?.OtherFee,
        Id: formData?.id,
        AuthCode: formData?.authCode,
        FormTitle: formData?.formTitle,
        Fund: formData?.fundName,
        InvestmentId: formData?.invId,
        InvestmentName: formData?.invName,
        MultipleProperties: formData?.multipleProps === "No" ? false : true,
        Address: formData?.address !== null ? formData?.address : "",
        TotalInvestmentShare: formData?.totalInvestmentShare,
        InvestmentOccupancy: formData?.investmentOccupancy,
        OwnershipType: formData?.ownershipType,
        Type: formData?.type,
        TiaaOwnershipShare: parseFloat(formData?.tiaaOwnershipShare),
        Status: formData?.status,
        VerbiageText:
          formData?.verbiageText === null ? "" : formData?.verbiageText,
        IsOmni:
          formData?.propertyManagementCompanyName !== null && isOmni
            ? true
            : false,
        ReasonForNonBudgetedProject: null,
        ProjectType: null,
        ProjectID: null,
        ProjectSource: null,
        Bid: null,
        SelectedBidReason: null,
        StartDate: null,
        Duration: null,
        EndDate: null,
        AuthorizationAmount: 0,
        OverBudgetReason: null,
        ApprovedProjectBudgetAmount: 0,
        FormType: null,
        PropertiesDetails: propertyProjectDetailsList,
        PropManagerName: authorizationData?.data?.propertyManager,
        PropManagerEmail: authorizationData?.data?.propertyManagerEmail,
        AssetManagerName: authorizationData?.data?.recommendedBy,
        AssetManagerEmail: authorizationData?.data?.recommendedByEmail,
        IsAmtHigherThanValuationPercentage: null,
        Approvers: authorizationData?.data?.selectedApprovers,
        documents: uploadedDoc,
      });
    }
  }, [
    propertyProjectDetails,
    authorizationData,
    commonSlice.formType,
    proposaldata,
    uploadedDoc,
  ]);

  const handleSave = async () => {
    dispatch(setLoading(true));

    const saveUrl = `${baseUrl}Authorizations/save`;
    await postApiCall(saveUrl, saveSubmitFormData)
      .then((response: any) => {
        if (String(response) == "Success") {
          dispatch(setLoading(false));
          setToast(true);
          setToastMessage({
            message: "Authorization saved sucessfully",

            severity: "success",
          });
          setTimeout(() => setToast(false), 4000);
        }
      })
      .catch((err: any) => {
        dispatch(setLoading(false));
        errorToastMsgwithCode(err);
      });
  };

  const handlePost = (
    action: string,
    approveRejectUrl: any,
    approveRequest: any
  ) => {
    switch (formData?.parentComponent?.toLocaleLowerCase()) {
      case "captialauthorization":
        var isCapitalAuthValidated = checkCapitalAuthValidation();

        if (isCapitalAuthValidated.authSumError) {
          fieldErrorToastMessage(
            "Property project detail section authorization amount does not equal improvement section authorization amount"
          );
        } else if (isCapitalAuthValidated.isReccomdedbyduplicate) {
          fieldErrorToastMessage("Please select user other than approvers");
        } else if (isCapitalAuthValidated.isDublicate) {
          fieldErrorToastMessage("Please select unique approver(s)");
        } else if (isCapitalAuthValidated.overAllError) {
          fieldErrorToastMessage("Please fill in the required field(s)");
        } else if (!isCapitalAuthValidated.overAllError) {
          if (action === "submit") {
            dispatch(setLoading(true));
            submitAPICall();
          } else if (action === "approve") {
            dispatch(setLoading(true));
            approveRejectAPICall(approveRejectUrl, approveRequest);
          }
        }
        break;
      case "leaseauth":
        var isLeaseAuthValidated = checkLeaseauthValidation();
        if (isLeaseAuthValidated.isDublicate) {
          fieldErrorToastMessage("Please select unique approver(s)");
        } else if (isLeaseAuthValidated.isReccomdedbyduplicate) {
          fieldErrorToastMessage("Please select user other than approvers");
        } else if (isLeaseAuthValidated.overAllError) {
          fieldErrorToastMessage("Please fill in the required field(s)");
        } else {
          if (action === "submit") {
            dispatch(setLoading(true));
            submitAPICall();
          } else if (action === "approve") {
            dispatch(setLoading(true));
            approveRejectAPICall(approveRejectUrl, approveRequest);
          }
        }
        break;
      case "appointmentlcnpmc":
        var isAppointmentValidated = AppointmentValidation();

        if (isAppointmentValidated.isDublicate) {
          fieldErrorToastMessage("Please select unique approver(s)");
        } else if (isAppointmentValidated.isReccomdedbyduplicate) {
          fieldErrorToastMessage("Please select user other than approvers");
        } else if (isAppointmentValidated.isError) {
          fieldErrorToastMessage("Please fill in the required field(s)");
        } else {
          if (action === "submit") {
            dispatch(setLoading(true));
            submitAPICall();
          } else if (action === "approve") {
            dispatch(setLoading(true));
            approveRejectAPICall(approveRejectUrl, approveRequest);
          }
        }
        break;
      case "modifications":
        var isModificationValidated = checkModificationValidation();

        if (isModificationValidated.isDublicate) {
          fieldErrorToastMessage("Please select unique approver(s)");
        } else if (isModificationValidated.isReccomdedbyduplicate) {
          fieldErrorToastMessage("Please select user other than approvers");
        } else if (isModificationValidated.overAllError) {
          fieldErrorToastMessage("Please fill in the required field(s)");
        } else {
          if (action === "submit") {
            dispatch(setLoading(true));
            submitAPICall();
          } else if (action === "approve") {
            dispatch(setLoading(true));
            approveRejectAPICall(approveRejectUrl, approveRequest);
          }
        }
        break;

      default:
        break;
    }
  };
  const checkModificationValidation = () => {
    var isError = false;
    const projectName =
      ModProjectDetailsData.name === "" ||
      ModProjectDetailsData.name === undefined ||
      ModProjectDetailsData.name === null;
    var approversError = approvalErrorHandler(
      authorizationData.data.selectedApprovers
        ? authorizationData.data.selectedApprovers
        : [],
      authorizationData.data.approversListModel
        ? authorizationData.data.approversListModel
        : []
    );
    var recommendedbyError = RecommendbyValidator(
      authorizationData.data.selectedApprovers
        ? authorizationData.data.selectedApprovers
        : [],
      authorizationData.data.recommendedByEmail
        ? authorizationData.data.recommendedByEmail
        : []
    );
    dispatch(
      updateErrorFields({
        ...submitErrorModel.data,
        modprojectname: projectName,
        modEcoterms: formData?.showEcoTerms
          ? String(ModProjectDetailsData.ecoTerms) === "" ||
            ModProjectDetailsData.ecoTerms === "undefined"
          : false,
        modAmount:
          ModProjectDetailsData.authAmount === 0 ||
          ModProjectDetailsData.authAmount === undefined ||
          ModProjectDetailsData.authAmount === "0" ||
          ModProjectDetailsData.authAmount === "",
        approversListError: approversError.errorArray,
        propManager:
          saveSubmitFormData?.PropManagerName === null ||
          saveSubmitFormData?.PropManagerName === undefined ||
          saveSubmitFormData?.PropManagerName?.length === 0,
        assetManager:
          saveSubmitFormData?.AssetManagerName === null ||
          saveSubmitFormData?.AssetManagerName === undefined ||
          recommendedbyError ||
          saveSubmitFormData?.AssetManagerName?.length === 0,
      })
    );
    if (
      (formData?.showEcoTerms &&
        (ModProjectDetailsData.ecoTerms === "" ||
          ModProjectDetailsData.ecoTerms === undefined ||
          ModProjectDetailsData.ecoTerms === "undefined")) ||
      ModProjectDetailsData.authAmount === "" ||
      ModProjectDetailsData.authAmount === "0" ||
      ModProjectDetailsData.authAmount === undefined ||
      ModProjectDetailsData.name === "" ||
      ModProjectDetailsData.name === undefined ||
      ModProjectDetailsData.name === null

    ) {
      isError = true;
      return {
        overAllError: isError,
        authSumError: false,
        isDublicate: approversError.isDublicate,
        isReccomdedbyduplicate: recommendedbyError,
      };
    }


    if (
      userdata?.isAssetManager &&
      saveSubmitFormData?.Approvers.length === 0
    ) {
      isError = true;
      return {
        overAllError: isError,
        authSumError: false,
        isDublicate: approversError.isDublicate,
        isReccomdedbyduplicate: recommendedbyError,
      };
    } else if (userdata?.isAssetManager) {
      if (isApprovalError(approversError.errorArray)) {
        isError = true;
        return {
          overAllError: isError,
          authSumError: false,
          isDublicate: approversError.isDublicate,
          isReccomdedbyduplicate: recommendedbyError,
        };
      }
    }
    isError =
      (formData?.showPropertyManager !== undefined &&
        formData?.showPropertyManager &&
        (authorizationData.data.propertyManager === null ||
          authorizationData.data.propertyManager === undefined ||
          authorizationData.data.propertyManager.length === 0)) ||
      authorizationData.data.recommendedBy === null ||
      authorizationData.data.recommendedBy === undefined ||
      authorizationData.data.recommendedBy.length === 0; 

    return {
      overAllError: isError,
      authSumError: false,
      isDublicate: approversError.isDublicate,
      isReccomdedbyduplicate: recommendedbyError,
    };

  };


  var AppointmentValidation = () => {
    const terminationfee =
      proposaldata?.TerminationFee === "" ||
      proposaldata?.TerminationFee === undefined ||
      proposaldata?.TerminationFee === null;
    const terminationtime =
      proposaldata.TerminationTime === "" ||
      proposaldata.TerminationTime === undefined ||
      proposaldata.TerminationTime === null;
    const projectName =
      proposaldata.projectName === "" ||
      proposaldata.projectName === undefined ||
      proposaldata.projectName === null;
    var approversError = approvalErrorHandler(
      authorizationData.data.selectedApprovers
        ? authorizationData.data.selectedApprovers
        : [],
      authorizationData.data.approversListModel
        ? authorizationData.data.approversListModel
        : []
    );
    var recommendedbyError = RecommendbyValidator(
      authorizationData.data.selectedApprovers
        ? authorizationData.data.selectedApprovers
        : [],
      authorizationData.data.recommendedByEmail
        ? authorizationData.data.recommendedByEmail
        : []
    );

    dispatch(
      updateErrorFields({
        ...submitErrorModel.data,
        appointmentterminationfee: terminationfee,
        appointemnetterminationtime: terminationtime,
        appprojectname: projectName,
        approversListError: approversError.errorArray,
        propManager:
          saveSubmitFormData?.PropManagerName === null ||
          saveSubmitFormData?.PropManagerName === undefined ||
          saveSubmitFormData?.PropManagerName?.length === 0,
        assetManager:
          saveSubmitFormData?.AssetManagerName === null ||
          saveSubmitFormData?.AssetManagerName === undefined ||
          recommendedbyError ||
          saveSubmitFormData?.AssetManagerName?.length === 0,
      })
    );
    if (
      userdata?.isAssetManager &&
      (saveSubmitFormData?.Approvers.length === 0 ||
        isApprovalError(approversError.errorArray))
    ) {
      return { isError: true, isDublicate: approversError.isDublicate };
    }
    var isError =
      (formData?.showPropertyManager !== undefined &&
        formData?.showPropertyManager &&
        (authorizationData.data.propertyManager === null ||
          authorizationData.data.propertyManager === undefined ||
          authorizationData.data.propertyManager.length === 0)) ||
      authorizationData.data.recommendedBy === null ||
      authorizationData.data.recommendedBy === undefined ||
      authorizationData.data.recommendedBy.length === 0;

    if (isError)
      return { isError: isError, isDublicate: approversError.isDublicate };

    return {
      isError: terminationfee || terminationtime || projectName,
      isDublicate: approversError.isDublicate,
      isReccomdedbyduplicate: recommendedbyError,
    };
  };

  const checkCapitalAuthIsError = (
    approversError: any,
    exceedingError: boolean,
    authSumError: boolean
  ) => {
    var isError = false;

    if (
      saveSubmitFormData?.Approvers.length === 0 &&
      userdata?.isAssetManager
    ) {
      return true;
    } else if (userdata?.isAssetManager) {
      if (isApprovalError(approversError)) {
        return true;
      }
    }

    if (
      saveSubmitFormData?.PropertiesDetails === null ||
      saveSubmitFormData?.PropertiesDetails === undefined ||
      saveSubmitFormData?.PropertiesDetails.length === 0
    ) {
    } else {
      if (
        isPropertyProjectDetailsError(saveSubmitFormData?.PropertiesDetails)
      ) {
        return true;
      }
    }

    if (
      typeof saveSubmitFormData?.StartDate !== "string" &&
      typeof saveSubmitFormData?.EndDate !== "string"
    ) {
      isError = saveSubmitFormData?.StartDate > saveSubmitFormData?.EndDate;
      if (isError) {
        return isError;
      }
    } else if (
      saveSubmitFormData?.StartDate === undefined ||
      saveSubmitFormData?.EndDate === undefined
    ) {
      return true;
    }

    if (
      commonSlice.formType &&
      formData?.propertyManagementCompanyName !== "OMNI"
    ) {
      isError =
        budgetedData?.data.projectType === null ||
        budgetedData?.data.projectType === undefined ||
        budgetedData?.data.projectType?.length === 0 ||
        budgetedData?.data.name === null ||
        budgetedData?.data.name?.length === 0 ||
        budgetedData?.data.name === undefined ||
        budgetedData?.data.bid === null ||
        budgetedData?.data.bid === undefined ||
        budgetedData?.data.bid?.length === 0 ||
        budgetedData?.data.authAmount === null ||
        budgetedData?.data.authAmount === undefined ||
        budgetedData?.data.authAmount <= 0 ||
        (toLower(budgetedData?.data.bid) ===
        toLower("Will not be competitively Bid")
          ? budgetedData?.data.nonCompetBidReason === null ||
            budgetedData?.data.nonCompetBidReason === undefined ||
            budgetedData?.data.nonCompetBidReason.length === 0
          : false) ||
        exceedingError ||
        authSumError;
    } else {
      isError =
        (formData?.propertyManagementCompanyName === "OMNI" &&
        !commonSlice.formType
          ? nonBudgetedData.data.rsnForNonBudgeted === null ||
            nonBudgetedData.data.rsnForNonBudgeted === undefined ||
            nonBudgetedData.data.rsnForNonBudgeted?.length === 0
          : false) ||
        nonBudgetedData.data.name === null ||
        nonBudgetedData.data.name === undefined ||
        nonBudgetedData.data.name?.length === 0 ||
        nonBudgetedData?.data?.bid === null ||
        nonBudgetedData?.data?.bid === undefined ||
        nonBudgetedData?.data?.bid?.length === 0 ||
        nonBudgetedData.data.projectId === null ||
        nonBudgetedData.data.projectId === undefined ||
        nonBudgetedData.data.projectId?.length === 0 ||
        nonBudgetedData?.data.projectType === null ||
        nonBudgetedData?.data.projectType?.length === 0 ||
        nonBudgetedData?.data.projectType === undefined ||
        (toLower(nonBudgetedData?.data.bid) ===
        toLower("Will not be competitively Bid")
          ? nonBudgetedData?.data.nonCompetBidReason === null ||
            nonBudgetedData?.data.nonCompetBidReason === undefined ||
            nonBudgetedData?.data.nonCompetBidReason?.length === 0
          : false) ||
        nonBudgetedData?.data.authorizationAmount === null ||
        nonBudgetedData?.data.authorizationAmount === undefined ||
        nonBudgetedData?.data.authorizationAmount <= 0 ||
        authSumError;
    }

    if (isError) {
      return true;
    }
    isError =
      (formData.disablePropertyManager || userdata?.isAdmin
        ? false
        : authorizationData.data.propertyManager === null ||
          authorizationData.data.propertyManager === undefined ||
          authorizationData.data.propertyManager.length === 0) ||
      authorizationData.data.recommendedBy === null ||
      authorizationData.data.recommendedBy === undefined ||
      authorizationData.data.recommendedBy.length === 0;

    return isError;
  };

  const checkCapitalAuthValidation = () => {
    var exceedingError =
      saveSubmitFormData?.AuthorizationAmount !== undefined
        ? saveSubmitFormData?.AuthorizationAmount >
            saveSubmitFormData?.ApprovedProjectBudgetAmount &&
          (saveSubmitFormData.OverBudgetReason === null ||
            saveSubmitFormData.OverBudgetReason === undefined ||
            saveSubmitFormData.OverBudgetReason.length === 0)
        : false;

    var propertyProjectError = validatePropertyProjectDetails(
      propertyProjectDetails.data.projectDetails
    );

    var authSumError = false;
    if (
      saveSubmitFormData?.PropertiesDetails === null ||
      saveSubmitFormData?.PropertiesDetails === undefined ||
      saveSubmitFormData?.PropertiesDetails.length === 0
    ) {
      authSumError = false;
    } else {
      var propertyProjectSum = 0;
      saveSubmitFormData?.PropertiesDetails.forEach((element: any) => {
        propertyProjectSum = propertyProjectSum + element.authorizationAmount;
      });
      authSumError =
        (propertyProjectSum !== saveSubmitFormData?.AuthorizationAmount) !==
        undefined
          ? propertyProjectSum !== saveSubmitFormData?.AuthorizationAmount
          : true;
    }

    var approversError = approvalErrorHandler(
      authorizationData.data.selectedApprovers
        ? authorizationData.data.selectedApprovers
        : [],
      authorizationData.data.approversListModel
        ? authorizationData.data.approversListModel
        : []
    );
    var recommendedbyError = RecommendbyValidator(
      authorizationData.data.selectedApprovers
        ? authorizationData.data.selectedApprovers
        : [],
      authorizationData.data.recommendedByEmail
        ? authorizationData.data.recommendedByEmail
        : []
    );
    dispatch(
      updateErrorFields({
        ...submitErrorModel.data,
        selectProject: commonSlice?.formType
          ? budgetedData?.data.name === null ||
            budgetedData?.data.name?.length === 0 ||
            budgetedData?.data.name === undefined
          : false,
        projectType: commonSlice?.formType
          ? budgetedData?.data.projectType === null ||
            budgetedData?.data.projectType === undefined ||
            budgetedData?.data.projectType?.length === 0
          : false,
        nbProjectType: !commonSlice?.formType
          ? nonBudgetedData?.data.projectType === null ||
            nonBudgetedData?.data.projectType?.length === 0 ||
            nonBudgetedData?.data.projectType === undefined
          : false,
        bid: commonSlice?.formType
          ? budgetedData?.data.bid === null ||
            budgetedData?.data.bid === undefined ||
            budgetedData?.data.bid?.length === 0
          : false,
        nbBid: !commonSlice?.formType
          ? nonBudgetedData?.data?.bid === null ||
            nonBudgetedData?.data?.bid === undefined ||
            nonBudgetedData?.data?.bid?.length === 0
          : false,
        authAmount: commonSlice?.formType
          ? budgetedData?.data.authAmount === null ||
            budgetedData?.data.authAmount === undefined ||
            budgetedData?.data.authAmount <= 0 ||
            Number.isNaN(budgetedData?.data.authAmount)
          : false,
        nbAuthAmount: !commonSlice?.formType
          ? nonBudgetedData?.data.authorizationAmount === null ||
            nonBudgetedData?.data.authorizationAmount === undefined ||
            nonBudgetedData?.data.authorizationAmount <= 0 ||
            Number.isNaN(nonBudgetedData?.data.authorizationAmount)
          : false,
        bidReason:
          commonSlice?.formType &&
          toLower(budgetedData?.data.bid) ===
            toLower("Will not be competitively Bid")
            ? budgetedData?.data.nonCompetBidReason === null ||
              budgetedData?.data.nonCompetBidReason === undefined ||
              budgetedData?.data.nonCompetBidReason.length === 0
            : false,
        nbBidReason:
          !commonSlice?.formType &&
          toLower(nonBudgetedData?.data.bid) ===
            toLower("Will not be competitively Bid")
            ? nonBudgetedData?.data.nonCompetBidReason === null ||
              nonBudgetedData?.data.nonCompetBidReason === undefined ||
              nonBudgetedData?.data.nonCompetBidReason?.length === 0
            : false,
        nonBudgetReason: !commonSlice?.formType
          ? nonBudgetedData.data.rsnForNonBudgeted === null ||
            nonBudgetedData.data.rsnForNonBudgeted === undefined ||
            nonBudgetedData.data.rsnForNonBudgeted?.length === 0
          : false,
        nbProjectName: !commonSlice?.formType
          ? nonBudgetedData.data.name === null ||
            nonBudgetedData.data.name === undefined ||
            nonBudgetedData.data.name?.length === 0
          : false,
        nbProjectId: !commonSlice?.formType
          ? nonBudgetedData.data.projectId === null ||
            nonBudgetedData.data.projectId === undefined ||
            nonBudgetedData.data.projectId?.length === 0
          : false,
        budgetedenddate: saveSubmitFormData?.EndDate === null,
        propManager:
          saveSubmitFormData?.PropManagerName === null ||
          saveSubmitFormData?.PropManagerName === undefined ||
          saveSubmitFormData?.PropManagerName?.length === 0,
        assetManager:
          saveSubmitFormData?.AssetManagerName === null ||
          saveSubmitFormData?.AssetManagerName === undefined ||
          recommendedbyError ||
          saveSubmitFormData?.AssetManagerName?.length === 0,
        exceededAuthAmount: commonSlice?.formType ? exceedingError : false,
        propertyProjectDetail: propertyProjectError,
        authAmountError: authSumError,
        approversListError: approversError.errorArray,
      })
    );

    return {
      overAllError: checkCapitalAuthIsError(
        approversError.errorArray,
        exceedingError,
        authSumError
      ),
      authSumError: authSumError,
      isDublicate: approversError.isDublicate,
      isReccomdedbyduplicate: recommendedbyError,
    };
  };

  const checkLeaseAuthIsError = (approversError: any) => {
    var isError = false;
   
    const leaseTermsPremisesEmpty =
      (saveSubmitFormData?.LeaseTerm === undefined ||
        saveSubmitFormData?.LeaseTerm === null ||
        Number.isNaN(saveSubmitFormData?.LeaseTerm) ||
        saveSubmitFormData?.LeaseTerm === 0) &&
      (saveSubmitFormData?.LeasePremises === undefined ||
        saveSubmitFormData?.LeasePremises === null ||
        saveSubmitFormData?.LeasePremises === 0 ||
        Number.isNaN(saveSubmitFormData?.LeasePremises));
    const projectDetailFieldError =
      leaseTermsPremisesEmpty && saveSubmitFormData?.AuthorizationAmount === 0;

    const marketRateError =
      !leaseTermsPremisesEmpty &&
      (saveSubmitFormData?.LeaseMarketRate === null ||
        saveSubmitFormData?.LeaseMarketRate === undefined);
    if (
      saveSubmitFormData?.Approvers.length === 0 &&
      userdata?.isAssetManager
    ) {
      return true;
    } else if (userdata?.isAssetManager) {
      if (isApprovalError(approversError)) {
        return true;
      }
    }

    if (
      projectDetailFieldError ||
      marketRateError ||
      (!formData?.showOfacCheck ? false : !saveSubmitFormData?.OfacDocCertified)
    ) {
      return true;
    }

    isError =
      (formData.disablePropertyManager || userdata?.isAdmin
        ? false
        : formData?.showPropertyManager !== undefined &&
          formData?.showPropertyManager &&
          (authorizationData.data.propertyManager === null ||
            authorizationData.data.propertyManager === undefined ||
            authorizationData.data.propertyManager.length === 0)) ||
      authorizationData.data.recommendedBy === null ||
      authorizationData.data.recommendedBy === undefined ||
      authorizationData.data.recommendedBy.length === 0 ||
      leaseProjectDetails.data.projectName === null ||
      leaseProjectDetails.data.projectName === "" ||
      leaseProjectDetails.data.projectName === undefined 



    return isError;
  };

  const checkLeaseauthValidation = () => {
    const projectName =
      leaseProjectDetails.data.projectName === "" ||
      leaseProjectDetails.data.projectName === undefined ||
      leaseProjectDetails.data.projectName === null;
    const leaseTermsPremisesEmpty =
      (saveSubmitFormData?.LeaseTerm === undefined ||
        saveSubmitFormData?.LeaseTerm === null ||
        Number.isNaN(saveSubmitFormData?.LeaseTerm) ||
        saveSubmitFormData?.LeaseTerm === 0) &&
      (saveSubmitFormData?.LeasePremises === undefined ||
        saveSubmitFormData?.LeasePremises === null ||
        saveSubmitFormData?.LeasePremises === 0 ||
        Number.isNaN(saveSubmitFormData?.LeasePremises));
    const projectDetailFieldError =
      leaseTermsPremisesEmpty && saveSubmitFormData?.AuthorizationAmount === 0;
    const marketRateError =
      !leaseTermsPremisesEmpty &&
      (saveSubmitFormData?.LeaseMarketRate === null ||
        saveSubmitFormData?.LeaseMarketRate === undefined ||
        saveSubmitFormData?.LeaseMarketRate === "");

    var approversError = approvalErrorHandler(
      authorizationData.data.selectedApprovers
        ? authorizationData.data.selectedApprovers
        : [],
      authorizationData.data.approversListModel
        ? authorizationData.data.approversListModel
        : []
    );
    var recommendedbyError = RecommendbyValidator(
      authorizationData.data.selectedApprovers
        ? authorizationData.data.selectedApprovers
        : [],
      authorizationData.data.recommendedByEmail
        ? authorizationData.data.recommendedByEmail
        : []
    );
    dispatch(
      updateErrorFields({
        ...submitErrorModel.data,
        leaseTerms: projectDetailFieldError,
        leasePremises: projectDetailFieldError,
        leaseMarketRate: marketRateError,
        leaseAmount: projectDetailFieldError,
        leaseprojectname: projectName,
        leaseOFAC: !saveSubmitFormData?.OfacDocCertified,
        approversListError: approversError.errorArray,
        propManager:
          saveSubmitFormData?.PropManagerName === null ||
          saveSubmitFormData?.PropManagerName === undefined ||
          saveSubmitFormData?.PropManagerName?.length === 0,
        assetManager:
          saveSubmitFormData?.AssetManagerName === null ||
          saveSubmitFormData?.AssetManagerName === undefined ||
          recommendedbyError ||
          saveSubmitFormData?.AssetManagerName?.length === 0,
      })
    );

    return {
      overAllError: checkLeaseAuthIsError(approversError.errorArray),
      isDublicate: approversError.isDublicate,
      isReccomdedbyduplicate: recommendedbyError,
    };
  };

  const handleRecall = async () => {
    var url = `${baseUrl}Authorizations/recall?guid=${formData.id}`;
    dispatch(setLoading(true));

    try {
      const res = await postApiCall(url, formData.id);
      setToast(true);
      setToastMessage({
        message: "Authorization recalled sucessfully",
        severity: "success",
      });
      setTimeout(() => setToast(false), 4000);
      window.location.reload();
      dispatch(setLoading(false));
    } catch (error) {
      errorToastMsgwithCode(error);
      dispatch(setLoading(false));
    }
  };

  const handleDeleteAuthorization = async () => {
    var url = `${baseUrl}Authorizations/deleteorrestore`;
    dispatch(setLoading(true));

    const deletePayload = {
      id: formData?.id,
      isDelete: true,
    };
    try {
      const res = await postApiCall(url, deletePayload);
      setToast(true);
      setToastMessage({
        message: "Authorization deleted sucessfully",
        severity: "success",
      });
      setTimeout(() => setToast(false), 4000);
      nav(`/dashboard`);
    } catch (error) {
      errorToastMsgwithCode(error);
    }
    dispatch(setLoading(false));
  };

  const handleRestore = async () => {
    var url = `${baseUrl}Authorizations/deleteorrestore`;
    dispatch(setLoading(true));

    const restorePayload = {
      id: formData?.id,
      isDelete: false,
    };
    try {
      const res = await postApiCall(url, restorePayload);
      setToast(true);
      setToastMessage({
        message: "Authorization restored sucessfully",
        severity: "success",
      });
      setTimeout(() => setToast(false), 4000);
      nav(`/dashboard`);
      dispatch(setLoading(false));
    } catch (error) {
      errorToastMsgwithCode(error);
      dispatch(setLoading(false));

      console.log("error in restore", error);
    }
  };

  const handleApproveOrReject = async () => {
    var tempAppArray: Array<any> = [];
    if (authorizationData?.data?.selectedApprovers) {
      tempAppArray = authorizationData?.data?.selectedApprovers.filter(
        (elm: any) => elm.name.length !== 0
      );
    }

    var approveRequest = {
      Id: formData?.id,
      isApproved: !isRejected,
      comment: approveRejectComment,
      approvers: tempAppArray ? tempAppArray : formData?.approvers,
      IsAmtHigherThanValuationPercentage: !isRejected
        ? authorizationData?.data?.isAmtHigherThanValuationPercentage
        : null,
    };
    const approveRejectUrl = `${baseUrl}Authorizations/approverorreject`;

    if (formData?.showApprovers && !isRejected) {
      handlePost("approve", approveRejectUrl, approveRequest);
    } else {
      dispatch(setLoading(true));
      approveRejectAPICall(approveRejectUrl, approveRequest);
    }
  };

  return (
    <>
      <Grid className="btn-padding" container>
        <Grid container item xs={12} sm={6} md={4}>
          {formData?.showSaveButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-btn-padding">
              <Button
                className="btn-primary"
                variant="contained"
                color="primary"
                onClick={() => handleSave()}
              >
                <SaveIcon sx={{ mr: "2.5px" }} /> Save
              </Button>
            </Grid>
          ) : null}

          {formData?.showCloseButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-btn-padding">
              <Button
                className="btn-tertiary"
                variant="contained"
                onClick={() => {
                  setTimeout(() => {
                    nav("/");
                  }, 0);
                }}
              >
                <CloseIcon sx={{ mr: "2.5px" }} /> Close
              </Button>
            </Grid>
          ) : null}

          {formData?.showDeleteButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-btn-padding">
              <Button
                className="btn-tertiary"
                onClick={() => {
                  setOpenDeletePopup(true);
                }}
                variant="contained"
                color="primary"
              >
                <DeleteIcon sx={{ mr: "2.5px" }} /> Delete
              </Button>
            </Grid>
          ) : null}
          {formData?.showRestoreButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-btn-padding">
              <Button
                className="btn-restore-width"
                onClick={() => {
                  setOpenRestorePopup(true);
                }}
                variant="contained"
                color="primary"
              >
                <RestoreFromTrashIcon sx={{ mr: "2.5px" }} /> Restore
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={8}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {formData?.showSubmitButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-right-btn">
              <Button
                className="btn-primary btn-float"
                variant="contained"
                color="primary"
                onClick={() => handlePost("submit", "", "")}
              >
                <DoneIcon sx={{ mr: "2.5px" }} /> Submit
              </Button>
            </Grid>
          ) : null}

          {formData?.showApproveButton ? (
            <Grid item xs={12} sm={4} md={2} className="grid-right-btn">
              <Button
                className="btn-float btn-restore-width"
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsRejected(false);
                  setApproveRejectDialogBox(true);
                }}
              >
                <ThumbUpAltIcon sx={{ mr: "2.5px" }} />
                Approve
              </Button>
            </Grid>
          ) : null}

          {formData?.showRejectButton ? (
            <Grid item xs={12} sm={4} md={2} className="grid-right-btn">
              <Button
                className="btn-tertiary btn-float"
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsRejected(true);
                  setApproveRejectDialogBox(true);
                }}
              >
                <BlockIcon sx={{ mr: "2.5px", whiteSpace: "nowrap" }} />
                Reject
              </Button>
            </Grid>
          ) : null}

          {formData?.showRecallButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-right-btn">
              <Button
                className="btn-primary btn-float"
                onClick={() => {
                  setOpenRecallPopup(true);
                }}
                variant="contained"
                color="primary"
              >
                <ReplayIcon sx={{ mr: "2.5px" }} />
                Recall
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}

      {approveRejectDialogBox && (
        <ApproveRejectDialog
          openDialogBox={approveRejectDialogBox}
          setOpenDialogBox={setApproveRejectDialogBox}
          setConfirm={handleApproveOrReject}
          setComment={setApproveRejectComment}
          isRejected={isRejected}
        />
      )}
      <DialogBox
        openDialogBox={openDeletePopup}
        setOpenDialogBox={setOpenDeletePopup}
        setConfirm={handleDeleteAuthorization}
        dialogTitle={"Delete confirmation"}
        dialogText={"Do you want to delete the selected authorization?"}
      />
      <DialogBox
        openDialogBox={openRestorePopup}
        setOpenDialogBox={setOpenRestorePopup}
        setConfirm={handleRestore}
        dialogTitle={"Restore confirmation"}
        dialogText={"Do you want to restore the selected authorization?"}
      />
      <DialogBox
        openDialogBox={openRecallPopup}
        setOpenDialogBox={setOpenRecallPopup}
        setConfirm={handleRecall}
        dialogTitle={"Recall confirmation"}
        dialogText={"Do you want to recall the selected authorization?"}
      />
    </>
  );
};

export default CapAuthActionButtons;
