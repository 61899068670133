import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridHeaderFilterCellProps,
  GridRowSelectionModel,
  GridRenderHeaderFilterProps,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { RemoveCircle } from "@mui/icons-material";
import { ToastMessage } from "../../../components/utils/ToastMessage";
import { LoadingButton } from "@mui/lab";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { capitalProjectsViewsColumn } from "./capitalProjectsViewsColumn";
import { toUpper } from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import * as XLSX from "xlsx";
import AddIcon from "@mui/icons-material/Add";
import { Remove } from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import dayjs, { Dayjs } from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import { GridValueFormatterParams } from "../../../interfaces/Interfaces";
import { AnyARecord } from "dns";
import { baseUrl } from "../../../components/config/Config";
import { postApiCall } from "../../../services/ApiHelper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CapitalProjectsDataGrid = (props: any) => {
  const [rowData, setRowData] = useState<any>();
  const [loadingButton, setLoadingButton] = useState(false);

  const [active, setActive] = useState<any>();
  const [headingFilters, setHeadingFilters] = useState<any>({});
  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [filterModelState, setFilterModelState] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [activeButton, setActiveButton] = useState<boolean>(false); // For controlling the active state of a button

  const handleSelectionChange = (newSelection: any) => {
    setSelectedIds(newSelection);
  };

  useEffect(() => {
    setRowData(props.rowdata);
    if (filterModelState?.items !== undefined) {
      filterData(filterModelState);
      
    }
    setHeadingFilters(props.gridDropDown);
  }, [props.rowdata]);

  const exportExcel = () => {
    setActiveButton(true)
    const data = rowData.map((row: any) =>
      capitalProjectsViewsColumn.map((col) => row[col.field] || "")
    );
    const worksheet: any = XLSX.utils.aoa_to_sheet([
      capitalProjectsViewsColumn.map((col) => col.headerName),
      ...data,
    ]);
    // Define the columns that contain amount values
    const amountColumns = ["amountBudgeted", "amountSpent"];

    // Apply right alignment to amount columns
    amountColumns.forEach((colName) => {
      const col = capitalProjectsViewsColumn.findIndex(
        (col) => col.field === colName
      );
      if (col !== -1) {
        const colLetter = XLSX.utils.encode_col(col);
        worksheet["!cols"] = worksheet["!cols"] || [];
        worksheet["!cols"][col] = {
          wch: 15,
          alignment: { horizontal: "right" },
        };

        for (let row = 2; row <= data.length + 1; row++) {
          const cellRef = `${colLetter}${row}`;
          if (worksheet[cellRef]) {
            worksheet[cellRef].z = "$#,##0.00"; // Apply number format
          }
        }
      }
      setActiveButton(false)
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DataGrid");
    const now = dayjs();
    const hour = now.format("HH");
    const minute = now.format("mm");
    const day = now.format("DD");
    const month = now.format("MM");
    const year = now.format("YYYY");
    const seconds = now.format("ss");
    const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;
    XLSX.writeFile(workbook, `capital_projects_${timestamp}.xlsx`);
  };

  const CustomTextHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    const { colDef, inputRef } = params;
    return (
      <TextField
        id="standard-basic"
        variant="standard"
        key={props.selectedTab || props.isActive}
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        onChange={(event) => {
          handleFilterChange(event.target.value, params.item.field);
        }}
        InputProps={{
          ref: inputRef
        }}
      />
    );
  };

  const dropDownFiltering = (
    value: Array<string>,
    field: string,
    filteredRows: any
  ) => {
    var filteredDropdownData: any = [];

    value.forEach((selectedDropdown: string) => {
      var filteredData = filteredRows.filter(
        (fundRow: any) =>
          fundRow[field] !== undefined &&
          toUpper(fundRow[field]) === toUpper(selectedDropdown)
      );

      filteredData.forEach((perFilterd: any) => {
        filteredDropdownData.push(perFilterd);
      });
    });

    return filteredDropdownData;
  };

  const handleFilterChange = (e: any, field: string) => {
    var value = e;
    var fieldName = field;
    var filterModel = {
      items: [],
    };
    setSelectedOptions({
      ...selectedOptions,
      ...{
        [fieldName]: value,
      },
    });
    filterModel.items = {
      ...selectedOptions,
      ...{
        [fieldName]: value,
      },
    };
    filterData(filterModel);
    setFilterModelState(filterModel);
  };

  const filterData = (filterModel: any) => {
    var filteredRowData: any = props.rowdata;

    for (const key of Object.keys(filterModel.items)) {
      switch (typeof filterModel.items[key]) {
        case "object":
          if (filterModel.items[key].length !== 0) {
            filteredRowData = dropDownFiltering(
              filterModel.items[key],
              key,
              filteredRowData
            );
          }
          break;
        case "string":
          if (filterModel.items[key].length !== 0) {
            filteredRowData = filteredRowData?.filter(
              (fundRow: any) =>
                fundRow[key] !== undefined &&
                toUpper(fundRow[key]).includes(toUpper(filterModel.items[key]))
            );
          }
          break;
        default:
          break;
      }
    }
    setRowData(filteredRowData);
  };

  const CustomDropDownHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    var columnFilterList = headingFilters[params.item.field] || [];
    const { colDef, inputRef } = params;

    return (
      <Autocomplete
        className="autocomplete-root"
        ListboxProps={{
          className: "autocomplete-dashboard"
        }}
        multiple
        limitTags={1}
        id="tags-standard"
        onChange={(event, newInputValue) => {
          handleFilterChange(newInputValue, params.item.field);
        }
        }
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        options={columnFilterList}
        disableCloseOnSelect
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option}
          </li>
        )}
        fullWidth
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
    );
  };

  const columns: any = capitalProjectsViewsColumn.map((colDef: any) => {
    let { valueFormatter, ...rest } = colDef;
    if (
      colDef.field === "invID" ||
      colDef.field === "invName" ||
      colDef.field === "createdBy" ||
      colDef.field === "createdOn" ||
      colDef.field === "updatedBy" ||
      colDef.field === "updatedAt"
    ) {
      return {
        ...rest,
        renderHeaderFilter: CustomDropDownHeaderFilter,
      };
    } else if (colDef.field === "projectID") {
      return {
        ...rest,
        renderHeaderFilter: CustomTextHeaderFilter,
        cellClassName: (params: GridCellParams<any, number>) => {
          return "project-name-cell";
        },
      };
    } else if (
      colDef.field === "amountBudgeted" ||
      colDef.field === "amountSpent"
    ) {
      return {
        ...rest,
        valueFormatter: (params: any) => {
          // Format the value as currency with dollar sign
          return `$${Number(params).toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`;
        },
        renderHeaderFilter: CustomTextHeaderFilter,
      };
    } else {
      return {
        ...rest,
        ...valueFormatter,
        renderHeaderFilter: CustomTextHeaderFilter,
      };
    }
  });

  const handledeactivate = async () => {
    if (selectedIds.length !== 0) {
      setLoadingButton(true);

      var url = `${baseUrl}capprojects/deactivate`;
      const payload = selectedIds;

      try {
        const res = await postApiCall(url, payload);
      } catch (error) {
        console.log(error);
      }
      props.submitdeactivate(true);
      setLoadingButton(false);
      setTimeout(() => props.submitdeactivate(false), 3000);
    }
  };

  const handleCellClick = (params: any) => {
    if (params.field === "projectID") {
      props.type("Edit");
      props.addProjectdropdown();
      props.updateRow({
        id: params.row.id,
        InvID: `${params.row.invID} - ${params.row.invName}`,
        projectID: params.row.projectID,
        name: params.row.name,
        budgetYear: params.row.budgetYear,
        AmountBudgeted: params.row.amountBudgeted,
        AmountSpent: params.row.amountSpent,
        comments: params.row.comments,
        isActive: params.row.isActive === "Yes",
      });
    }
  };
  const disabled = selectedIds.length === 0;
  return (
    <>
      <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <LoadingButton
           disabled={disabled}
          //  className="upload-btn"
          className= {disabled ? 'upload-btn' : 'deactivate'}
            loading={loadingButton}
            onClick={handledeactivate}
            variant="contained"

          >
            <Remove sx={{ mr: 1 }} />
            DEACTIVATE
          </LoadingButton>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className="primary"
            onClick={() => {
              props.addProjectdropdown();
              props.type("Add");
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            Add
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton loading = {activeButton} variant="contained" className="primary" onClick={exportExcel}>
            <FileDownloadOutlinedIcon sx={{ mr: 1 }} />
            EXCEL
          </LoadingButton>
        </Grid>
      </Grid>
      <Box className="dashboard-table-wrapper">
        <div style={{ width: "100%" }}>
          <DataGridPro
            className="dashboard-dataGrid"
            getRowId={(row) => row.id}
            rows={rowData ?? []}
            columns={columns}
            headerFilters
            checkboxSelection
            onRowSelectionModelChange={(newSelection) =>
              handleSelectionChange(newSelection)
            }
            isRowSelectable={(params) => params.row.isActive === "Yes"}
            columnHeaderHeight={48}
            headerFilterHeight={48}
            pageSizeOptions={[25, 50, 100, 200]}
            rowHeight={40}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            loading={props.loading}
            pagination
            disableColumnMenu={true}
            autoHeight
            onCellClick={handleCellClick}
            hideFooter={false}
          />
        </div>
      </Box>
    </>
  );
};

export default CapitalProjectsDataGrid;
