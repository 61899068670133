import * as React from "react";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
 
const STORAGE_KEY = 'session_timeout_state';
const ACTIVITY_KEY = 'last_user_activity';
const POPUP_DELAY = 900000; // Testing: 10 seconds (change back to 900000 for production)
const TIMEOUT_COUNTDOWN = 300; // 5 min for popup countdown to signout
const CHECK_INTERVAL = 1000; // Check activity every second
 
const eventTypes = ["keypress", "mousemove", "mousedown", "scroll", "touchmove", "pointermove"];
 
export const SessionTimeoutPopup = () => {
  const { instance } = useMsal();
  const [isWarningPopupOpen, setWarningPopupOpen] = useState(false);
  const [counter, setCounter] = useState(TIMEOUT_COUNTDOWN);
 
  const padTime = (time: number) => String(time).length === 1 ? `0${time}` : `${time}`;
 
  const format = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${padTime(seconds)}`;
  };
 
  const signOutHandler = async () => {
    try {
      localStorage.setItem('msal_session_timeout_logout', Date.now().toString());
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/"
      });
    } catch (error) {
      console.error('Logout error:', error);
    }
  };
 
  const updateLastActivity = () => {
    const currentTime = Date.now();
    localStorage.setItem(ACTIVITY_KEY, currentTime.toString());
  };
 
  const resetTimeOut = () => {
    setWarningPopupOpen(false);
    setCounter(TIMEOUT_COUNTDOWN);
    updateLastActivity();
    localStorage.setItem(STORAGE_KEY, JSON.stringify({
      isWarningPopupOpen: false
    }));
  };
 
  useEffect(() => {
    let activityCheckInterval: NodeJS.Timeout | null = null;
    let counterId: NodeJS.Timeout | null = null;
 
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === ACTIVITY_KEY) {
        // Reset warning popup if another tab records activity
        setWarningPopupOpen(false);
        setCounter(TIMEOUT_COUNTDOWN);
      }
     
      if (event.key === STORAGE_KEY) {
        const data = JSON.parse(event.newValue || '{}');
        setWarningPopupOpen(data.isWarningPopupOpen);
      }
     
      if (event.key === 'msal_session_timeout_logout') {
        signOutHandler();
      }
    };
 
    const checkInactivity = () => {
      const lastActivity = parseInt(localStorage.getItem(ACTIVITY_KEY) || Date.now().toString());
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - lastActivity;
 
      if (timeSinceLastActivity >= POPUP_DELAY && !isWarningPopupOpen) {
        setWarningPopupOpen(true);
        localStorage.setItem(STORAGE_KEY, JSON.stringify({
          isWarningPopupOpen: true
        }));
      }
    };
 
    const handleUserActivity = () => {
      if (!isWarningPopupOpen) {
        updateLastActivity();
      }
    };
 
    // Initialize activity tracking
    updateLastActivity();
 
    // Start checking for inactivity
    activityCheckInterval = setInterval(checkInactivity, CHECK_INTERVAL);
 
    // Add event listeners
    eventTypes.forEach(type => {
      window.addEventListener(type, handleUserActivity, false);
    });
    window.addEventListener('storage', handleStorageChange);
 
    // Handle countdown timer
    if (isWarningPopupOpen && counter > 0) {
      counterId = setInterval(() => {
        setCounter(prev => {
          const newCount = prev - 1;
          if (newCount === 0) {
            signOutHandler();
          }
          return newCount;
        });
      }, 1000);
    }
 
    // Cleanup
    return () => {
      if (activityCheckInterval) clearInterval(activityCheckInterval);
      if (counterId) clearInterval(counterId);
      eventTypes.forEach(type => {
        window.removeEventListener(type, handleUserActivity, false);
      });
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isWarningPopupOpen, counter, instance]);
 
  return (
    <div>
      {isWarningPopupOpen && (
        <Dialog
          open={isWarningPopupOpen}
          onClose={resetTimeOut}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="common-dialog-box"
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Session Timeout{" "}
            <IconButton
              edge="start"
              color="inherit"
              onClick={resetTimeOut}
              aria-label="close"
              className="close-btn"
            >
              <CloseIcon sx={{ mt: "-6px" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ padding: "0px" }} className="m-20 p-10">
            <AccessAlarmIcon
              fontSize="large"
              sx={{
                color: "#0263E0",
                display: "flex",
                flexDirection: "column",
                m: "auto",
                width: "fit-content",
              }}
            />
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                display: "flex",
                flexDirection: "column",
                m: "auto",
                width: "fit-content",
              }}
            >
              Your session is about to expire.
            </DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                m: "auto",
                width: "fit-content",
                fontSize: "20px",
              }}
            >
              {format(counter)}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<RestartAltIcon />}
              onClick={resetTimeOut}
              className="primary"
              variant="contained"
            >
              Reset
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};