import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
} from "./FileUpload.styles";
import { ToastMessage } from "../../utils/ToastMessage";
import { toast } from "../../../interfaces/Interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { LoadingButton } from "@mui/lab";
import { getApiCall, postApiCall } from "../../../services/ApiHelper";
import axios from "axios";
import { baseUrl } from "../../config/Config";
import { getAllDocuments } from "../../../redux/slices/documentSlice";
import { getFormData } from "../../../redux/slices/getFormDataSlice";

const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = (bytes: any) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = () => {

  const dispatch = useAppDispatch()
  const fileInputField = useRef(null);
  const [files, setFiles] = useState<any>({});
  const [toast, setToast] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const formData = useAppSelector(getFormData);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const addNewFiles = (newFiles: any) => {
    var ApprovedfileType = ["bmp", "csv", "xls", "xlsb", "xlsm", "xlsx", "docx", "dotx", "emp", "heic", "htm", "html", "jpeg", "jpg", "msg", "pdf", "png", "pptx", "rtf", "tif", "txt", "xps", "zip", "mov" , "tng" , "docs"]
    for (let file of newFiles) {
      const fileType = file.name.split(".").slice(-1)[0]?.toLowerCase();
      if (!(ApprovedfileType.includes(fileType))) {
        setToast(true);
        setToastMessage({
          message: "Please upload file with valid extension",
          severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
      }
      else if(files.name){

      }
      else {
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const handleNewFileUpload = (e: any) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
    }
  };

  const handleuploadButtonClick = () => {
    Object.values(files).map(async (file: any) => {
      setLoadingButton(true);
      if (file) {
        let fileName: string = file.name;
        let authorizationId: any = formData?.id;
        let encodedFileName: string = encodeURIComponent(fileName);
        var url = `${baseUrl}Document/upload?authId=${authorizationId}&fileName=${encodedFileName}`;
        await getApiCall(url)
          .then((response: any) => {
            if (!!response.url) {
              uploadPresignedUrl(response.url, file, authorizationId);
            } else {
              setToast(true);
              setLoadingButton(false);
              setToastMessage({
                message: "Technical issue incountered while uploading",
                severity: "error",
              });
              setTimeout(() => setToast(false), 4000);
            }
          })
          .catch((err: any) => {
            const msg = err.response.data.description;
            setToast(true);
            setLoadingButton(false);
            setToastMessage({
              message: msg,
              severity: "error",
            });
            setTimeout(() => setToast(false), 4000);
          });
      }
    });
  }
  const uploadPresignedUrl = async (res: any, file: any, authorizationId: any) => {
    if (file) {
      try {
        await axios.put(res, file, {
          headers: {
            "Content-Type": file.type,
          },
        }).then(async (response: any) => {
          if (response.status === 200) {
            var url = `${baseUrl}Document/SaveDocToDatabase`
            const SaveDocToDBResponse =
            {
              authId: authorizationId,
              docName: file.name
            }
            try {
              await postApiCall(url, SaveDocToDBResponse).then((response: any) => {
                if (response === true) {
                  setFiles({})
                  setLoadingButton(false);
                  dispatch(getAllDocuments(authorizationId));
                } else {
                  setToast(true);
                  setLoadingButton(false);
                  setToastMessage({
                    message: "Technical issue incountered while uploading",
                    severity: "error",
                  });
                  setTimeout(() => setToast(false), 4000);
                }
              })
            }
            catch (err: any) {
              const msg = err.response.data.description;
              setToast(true);
              setLoadingButton(false);
              setToastMessage({
                message: msg,
                severity: "error",
              });
              setTimeout(() => setToast(false), 4000);
            }
          }
        });
      } catch (err: any) {
        const msg = err.response.data.description;
        setToast(true);
        setLoadingButton(false);
        setToastMessage({
          message: msg,
          severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
      }
    }
  };

  const removeFile = (fileName: any) => {
    delete files[fileName];
    setFiles({ ...files });
  };

  return (
    <>
      <FileUploadContainer className="upload-file-wrap">
        <CloudUploadIcon />
        <DragDropText>Drop files to attach, or browse</DragDropText>
        <b>Valid File types:bmp,csv,xls,xlsb,xlsm,xlsx,docx,dotx,emp,heic,htm,html,jpeg,jpg,msg,pdf,png,pptx,rtf,tif,txt,xps,zip,mov,docs,tng </b>
        <FormField
          data-testid = "formField"
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          multiple
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        <PreviewList
        data-testid = "previewList">
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={fileName}>
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <CloseIcon onClick={() => removeFile(fileName)} />
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
      <LoadingButton
        disabled={(Object.keys(files).length <= 0)}
        className="upload-btn"
        loading={loadingButton}
        onClick={handleuploadButtonClick}
        variant="contained"
        component="span"
      >
        Upload Documents
      </LoadingButton>
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity="error"
        />
      )}
    </>
  );
};

export default FileUpload;


